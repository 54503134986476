import axios from "axios";
import * as APIUrl from "../../APIUrl";

const config = {
  headers: {
    "Content-Type": "application/json",
    accept: "application/json",
  },
};

export const addItem = (payload) => {
  try {
    return axios({
      method: "post",
      url: APIUrl.ADD_ITEM,
      data: payload,
      headers: {
        "Content-Type": "application/octet-stream",
      },
    });
  } catch (error) {
    throw new Error(error);
  }
};

export const updateItem = (payload) => {
  try {
    return axios({
      method: "post",
      url: APIUrl.UPDATE_ITEM,
      data: payload,
    });
  } catch (error) {
    throw new Error(error);
  }
};

export const fetchItemByItemId = (id) => {
  try {
    return axios({
      method: "get",
      url: APIUrl.FETCH_ITEM_BY_ITEM_ID + id,
    });
  } catch (error) {
    throw new Error(error);
  }
};

export const generateBarcode = (payload) => {
  try {
    return axios({
      method: "post",
      url:
        APIUrl.GENERATE_BARCODE +
        `?item_id=${payload.item_id}&property_id=${payload.property_id}`,
    });
  } catch (error) {
    throw new Error(error);
  }
};

export const fetchAddonByAddonId = (payload) => {
  try {
    return axios({
      method: "get",
      url: APIUrl.FETCH_ADDON_BY_ADDONID + payload.addon_id,
    });
  } catch (error) {
    throw new Error(error);
  }
};

export const fetchComboItemById = (payload) => {
  try {
    return axios({
      method: "get",
      url:
        APIUrl.FETCH_COMBO_ITEM_BY_ID +
        `${payload.combo_group_id}&item_id=${payload.item_id}`,
    });
  } catch (error) {
    throw new Error(error);
  }
};

export const deleteItem = (id) => {
  try {
    return axios({
      method: "get",
      url: APIUrl.DELETE_ITEM + id,
    });
  } catch (error) {
    throw new Error(error);
  }
};

export const fetchItem = (id) => {
  try {
    return axios({
      method: "get",
      url: APIUrl.FETCH_ITEM + id,
    });
  } catch (error) {
    throw new Error(error);
  }
};
export const fetchComboStatus = (id) => {
  try {
    return axios({
      method: "get",
      url: APIUrl.FETCH_COMBO_STATUS + id,
    });
  } catch (error) {
    throw new Error(error);
  }
};

export const duplicateItem = (payload) => {
  try {
    return axios({
      method: "get",
      url:
        APIUrl.DUPLICATE_ITEM +
        `${payload.itemId}&category_id=${payload.category}&item_name=${payload.name}&sort_order=${payload.sort_order}`,
    });
  } catch (error) {
    throw new Error(error);
  }
};

export const duplicateAddOn = (payload) => {
  try {
    return axios({
      method: "post",
      url:
        APIUrl.DUPLICATE_ADDON +
        `${payload.source_addon_id}&sort_order=${payload.sort_order}&target_addon_category_id=${payload.target_addon_category_id}`,
    });
  } catch (error) {
    throw new Error(error);
  }
};

export const fetchExtrasOptionsByItemId = (id) => {
  try {
    return axios({
      method: "get",
      url: APIUrl.FETCH_ADDONS_BY_ITEMID + id,
    });
  } catch (error) {
    throw new Error(error);
  }
};

export const addExtrasOptions = (payload) => {
  try {
    return axios({
      method: "post",
      url: APIUrl.ADD_OPTIONS_EXTRAS,
      data: payload,
      config,
    });
  } catch (error) {
    throw new Error(error);
  }
};

export const addComboItems = (payload) => {
  try {
    return axios({
      method: "post",
      url: APIUrl.ADD_COMBO_ITEMS,
      data: payload,
      config,
    });
  } catch (error) {
    throw new Error(error);
  }
};

export const deleteExtrasOptions = (id) => {
  try {
    return axios({
      method: "get",
      url: APIUrl.DELETE_OPTIONS_EXTRAS + id,
    });
  } catch (error) {
    throw new Error(error);
  }
};

export const deleteComboItem = (payload) => {
  try {
    return axios({
      method: "delete",
      url:
        APIUrl.DELETE_COMBO_ITEM +
        `${payload.combo_group_id}&item_id=${payload.item_id}`,
    });
  } catch (error) {
    throw new Error(error);
  }
};

export const updateExtrasOptions = (payload) => {
  try {
    return axios({
      method: "post",
      url: APIUrl.UPDATE_OPTIONS_EXTRAS,
      data: payload,
      config,
    });
  } catch (error) {
    throw new Error(error);
  }
};

export const updateComboItems = (payload) => {
  try {
    return axios({
      method: "put",
      url: APIUrl.UPDATE_COMBO_ITEM,
      data: payload,
      config,
    });
  } catch (error) {
    throw new Error(error);
  }
};

export const fetchUpsellItems = (payload) => {
  try {
    return axios({
      method: "get",
      url: APIUrl.FETCH_UPSELL_ITEMS + payload,
    });
  } catch (error) {
    throw new Error(error);
  }
};

export const fetchItemTags = (payload) => {
  try {
    return axios({
      method: "get",
      url: APIUrl.FETCH_TAGS + `${payload}`,
    });
  } catch (error) {
    throw new Error(error);
  }
};

export const deleteBarCode = (id) => {
  try {
    return axios({
      method: "get",
      url: APIUrl.DELETE_BAR_CODE + id,
    });
  } catch (error) {
    throw new Error(error);
  }
};
export const updateBarcode = (payload) => {
  try {
    return axios({
      method: "put",
      url:
        APIUrl.UPDATE_BARCODE +
        `?bar_code=${payload.item_bar_code}&item_id=${payload.item_id}&property_id=${payload.property_id}`,
    });
  } catch (error) {
    throw new Error(error);
  }
};

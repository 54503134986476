import React from "react";
import { Route, Redirect } from "react-router-dom";
import Sidenavigation from "../../components/layouts/Sidenavigation";
import Topnavigation from "../../components/layouts/Topnavigation";
import { useSelector } from "react-redux";

const PrivateRoutes = ({ component: Component, ...rest }) => {
  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
  return (
    <Route
      {...rest}
      render={(props) =>
        isLoggedIn ? (
          <div className="ms-body ms-aside-left-open ms-primary-theme ms-has-quickbar">
            <Sidenavigation />
            <main className="body-content">
              <Topnavigation />
              <div className="ms-content-wrapper">
                <div className="row">
                  <div className="col-md-12">
                    <Component {...props} />
                  </div>
                </div>
              </div>
            </main>
          </div>
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
};

export default PrivateRoutes;

import axios from "axios";
import * as APIUrl from "../APIUrl";

export const createPaymentAccount = (payload) => {
  try {
    return axios({
      method: "post",
      url: APIUrl.CREATE_PAYMENT_ACCOUNT,
      data: payload,
    });
  } catch (error) {
    throw new Error(error);
  }
};

export const updateCompanyDetails = (payload) => {
  try {
    return axios({
      method: "post",
      url: APIUrl.UPDATE_COMPANY_DETAILS,
      data: payload,
    });
  } catch (error) {
    throw new Error(error);
  }
};

export const updatePersonalDetailsWithDocument = (payload) => {
  try {
    return axios({
      method: "post",
      url: APIUrl.UPDATE_PERSONAL_DETAILS,
      data: payload,
    });
  } catch (error) {
    throw new Error(error);
  }
};

export const updateBankDetails = (payload) => {
  try {
    return axios({
      method: "post",
      url: APIUrl.UPDATE_BANK_DETAILS,
      data: payload,
    });
  } catch (error) {
    throw new Error(error);
  }
};

export const updateTermsAndCondition = (property_id) => {
  try {
    return axios({
      method: "post",
      url: APIUrl.UPDATE_TERMS_AND_CONDITION + property_id,
    });
  } catch (error) {
    throw new Error(error);
  }
};

export const updateReceipt = (payload) => {
  try {
    return axios({
      method: "post",
      url:
        APIUrl.UPDATE_RECEIPT +
        `${payload.property_id}&receipt_note=${payload.receipt_note}`,
    });
  } catch (error) {
    throw new Error(error);
  }
};

export const fetchReceipt = (payload) => {
  try {
    return axios({
      method: "get",
      url: APIUrl.FETCH_RECEIPT + `${payload.property_id}`,
    });
  } catch (error) {
    throw new Error(error);
  }
};

export const updateTaxDetails = (payload) => {
  try {
    return axios({
      method: "post",
      url: APIUrl.UPDATE_TAX_DETAILS,
      data: payload,
    });
  } catch (error) {
    throw new Error(error);
  }
};

export const fetchAccountStatus = (payload) => {
  try {
    return axios({
      method: "get",
      url:
        APIUrl.FETCH_ACCOUNT_DETAILS +
        `${payload.property_id}&username=${payload.username}`,
    });
  } catch (error) {
    throw new Error(error);
  }
};

export const fetchAccountSetting = (payload) => {
  try {
    return axios({
      method: "get",
      url: APIUrl.FETCH_ACCOUNT_SETTINGS + `${payload.property_id}`,
    });
  } catch (error) {
    throw new Error(error);
  }
};

export const fetchFranchiseInfomation = (payload) => {
  try {
    return axios({
      method: "get",
      url: APIUrl.FETCH_FRANCHISE_INFO + `${payload.property_id}`,
    });
  } catch (error) {
    throw new Error(error);
  }
};
export const fetchVendorInfomation = (payload) => {
  try {
    return axios({
      method: "get",
      url: APIUrl.FETCH_VENDOR_INFO + `${payload.property_id}`,
    });
  } catch (error) {
    throw new Error(error);
  }
};
export const fetchSplitPayment = (payload) => {
  try {
    return axios({
      method: "get",
      url: APIUrl.FETCH_SPLIT_PAYMENTS + `${payload.property_id}`,
    });
  } catch (error) {
    throw new Error(error);
  }
};

export const updateSplitPayment = (payload) => {
  try {
    return axios({
      method: "post",
      url: APIUrl.UPDATE_SPLIT_PAYMENTS,
      data: payload,
    });
  } catch (error) {
    throw new Error(error);
  }
};

export const fetchMerchantCategoryCodes = () => {
  try {
    return axios({
      method: "get",
      url: APIUrl.FETCH_MCC_MERCHANT_CATEGORY_CODE,
    });
  } catch (error) {
    throw new Error(error);
  }
};

export const fetchAccountId = (property_id) => {
  try {
    return axios({
      method: "get",
      url: APIUrl.FETCH_ACCOUNT_ID + property_id,
    });
  } catch (error) {
    throw new Error(error);
  }
};

export const fetchTermsAndCondition = (country_code) => {
  try {
    return axios({
      method: "get",
      url: APIUrl.FETCH_STRIPE_TERMS_AND_CONDITION + country_code,
    });
  } catch (error) {
    throw new Error(error);
  }
};

export const fetchPayouts = (payload) => {
  try {
    return axios({
      method: "get",
      url:
        APIUrl.FETCH_PAYOUT +
        payload.property_id +
        `&page=${payload.page}&size=${payload.size}`,
    });
  } catch (error) {
    throw new Error(error);
  }
};

export const fetchTransactions = (payload) => {
  try {
    return axios({
      method: "get",
      url:
        APIUrl.FETCH_TRANSACTIONS +
        payload.property_id +
        `&page=${payload.page}&size=${payload.size}`,
    });
  } catch (error) {
    throw new Error(error);
  }
};

export const filterAllTrancactionsByDate = (payload) => {
  let filter_url = "";

  if (
    payload.from_date === undefined &&
    payload.search_text === "" &&
    payload.sortValue === undefined &&
    payload.sort === undefined
  ) {
    // Pagination
    filter_url =
      APIUrl.FILTER_TRANSACTIONS_BY_DATE +
      `${payload.page}&size=${payload.size}&property_id=${payload.property_id}`;
  } else if (
    payload.from_date === undefined &&
    payload.search_text !== undefined &&
    payload.sortValue === undefined &&
    payload.sort === undefined
  ) {
    // Filter
    filter_url =
      APIUrl.FILTER_TRANSACTIONS_BY_DATE +
      `${payload.page}&size=${payload.size}&search_text=${payload.search_text}&property_id=${payload.property_id}`;
  } else if (
    payload.from_date != undefined &&
    payload.search_text === undefined &&
    payload.sortValue === undefined &&
    payload.sort === undefined
  ) {
    // Initial Load
    filter_url =
      APIUrl.FILTER_TRANSACTIONS_BY_DATE +
      `${payload.page}&end_date=${payload.to_date}&size=${payload.size}&start_date=${payload.from_date}&property_id=${payload.property_id}`;
  } else if (payload.sort_value !== undefined && payload.sort !== undefined) {
    // Initial Load
    filter_url =
      APIUrl.FILTER_TRANSACTIONS_BY_DATE +
      `${payload.page}&end_date=${payload.to_date}&size=${payload.size}&start_date=${payload.from_date}&property_id=${payload.property_id}&sort_value=${payload.sort_value}&sort=${payload.sort}`;
  } else {
    filter_url =
      APIUrl.FILTER_TRANSACTIONS_BY_DATE +
      `${payload.page}&size=${payload.size}&property_id=${payload.property_id}`;
  }

  try {
    return axios({
      method: "get",
      // url: APIUrl.FILTER_ORDER_BY_DATE + `${payload.page}&end_date=${payload.to_date}&size=${payload.size}&start_date=${payload.from_date}`
      url: filter_url,
    });
  } catch (error) {
    console.log("Error in All Order ::", error);
    throw new Error(error);
  }
};

export const filterAllTrancactionsBySearch = (payload) => {
  let filter_url = "";

  if (payload.from_date === undefined && payload.search_text === "") {
    // Pagination
    filter_url =
      APIUrl.FILTER_TRANSACTIONS_BY_SEARCH +
      `${payload.page}&size=${payload.size}&property_id=${payload.property_id}`;
  } else if (
    payload.from_date === undefined &&
    payload.search_text !== undefined
  ) {
    // Filter
    filter_url =
      APIUrl.FILTER_TRANSACTIONS_BY_SEARCH +
      `${payload.page}&size=${payload.size}&search_text=${payload.search_text}&property_id=${payload.property_id}`;
  } else if (
    payload.from_date != undefined &&
    payload.search_text === undefined
  ) {
    // Initial Load
    filter_url =
      APIUrl.FILTER_TRANSACTIONS_BY_SEARCH +
      `${payload.page}&end_date=${payload.to_date}&size=${payload.size}&start_date=${payload.from_date}&property_id=${payload.property_id}`;
  } else {
    filter_url =
      APIUrl.FILTER_TRANSACTIONS_BY_SEARCH +
      `${payload.page}&size=${payload.size}&property_id=${payload.property_id}`;
  }

  try {
    return axios({
      method: "get",
      // url: APIUrl.FILTER_ORDER_BY_DATE + `${payload.page}&end_date=${payload.to_date}&size=${payload.size}&start_date=${payload.from_date}`
      url: filter_url,
    });
  } catch (error) {
    console.log("Error in All Order ::", error);
    throw new Error(error);
  }
};

export const fetchPayoutReports = (payload) => {
  try {
    return axios({
      method: "get",
      url: APIUrl.FETCH_PAYOUT_REPORT + payload.payout_id,
      responseType: "blob",
    });
  } catch (error) {
    throw new Error(error);
  }
};
export const fetchQrReports = (payload) => {
  try {
    return axios({
      method: "get",
      url: APIUrl.FETCH_QR_REPORT + payload.subscription_id,
      responseType: "blob",
    });
  } catch (error) {
    throw new Error(error);
  }
};
export const fetchWebReports = (payload) => {
  try {
    return axios({
      method: "get",
      url: APIUrl.FETCH_WEB_REPORT + payload.web_id,
      responseType: "blob",
    });
  } catch (error) {
    throw new Error(error);
  }
};
export const fetchNashReports = (payload) => {
  try {
    return axios({
      method: "get",
      url: APIUrl.FETCH_NASH_REPORT + payload.nash_id,
      responseType: "blob",
    });
  } catch (error) {
    throw new Error(error);
  }
};

export const fetchPayoutDetails = (payload) => {
  try {
    return axios({
      method: "get",
      url: APIUrl.FETCH_PAYOUT_DETAILS + `${payload.payout_id}`,
    });
  } catch (error) {
    throw new Error(error);
  }
};

export const fetchTransactionDetails = (payload) => {
  try {
    return axios({
      method: "get",
      url: APIUrl.FETCH_TRANSACTIONS_DETAILS + `${payload.transaction_id}`,
    });
  } catch (error) {
    throw new Error(error);
  }
};

export const fetchOrderDetailsByPayoutId = (payload) => {
  try {
    return axios({
      method: "get",
      url:
        APIUrl.FETCH_ORDER_DETAILS_BY_PAYOUT_ID +
        `${payload.payment_id}&property_id=${payload.property_id}`,
    });
  } catch (error) {}
};

export const downloadTransactions = (payload) => {
  try {
    return axios({
      method: "get",
      url:
        APIUrl.DOWNLOAD_TRANSACTIONS_CSV +
        `${payload.to_date}&start_date=${payload.from_date}&search_text=${payload.search_text}&property_id=${payload.property_id}`,
      responseType: "blob",
    });
  } catch (error) {
    throw new Error(error);
  }
};

export const fetchWebInvoice = (payload) => {
  try {
    return axios({
      method: "get",
      url:
        APIUrl.FETCH_WEB_INVOICE +
        payload.property_id +
        `&page=${payload.page}&size=${payload.size}`,
    });
  } catch (error) {
    throw new Error(error);
  }
};
export const fetchQrInvoice = (payload) => {
  try {
    return axios({
      method: "get",
      url:
        APIUrl.FETCH_QR_INVOICE +
        payload.property_id +
        `&page=${payload.page}&size=${payload.size}`,
    });
  } catch (error) {
    throw new Error(error);
  }
};
export const fetchNashInvoice = (payload) => {
  try {
    return axios({
      method: "get",
      url:
        APIUrl.FETCH_NASH_INVOICE +
        payload.property_id +
        `&page=${payload.page}&size=${payload.size}`,
    });
  } catch (error) {
    throw new Error(error);
  }
};
export const fetchEposNowInvoice = (payload) => {
  try {
    return axios({
      method: "get",
      url: APIUrl.FETCH_EPOSNOW_INVOICE + payload.property_id,
    });
  } catch (error) {
    throw new Error(error);
  }
};
export const fetchEposReports = (payload) => {
  try {
    return axios({
      method: "get",
      url: APIUrl.FETCH_EPOSNOW_REPORT + payload.integration_Id,
      responseType: "blob",
    });
  } catch (error) {
    throw new Error(error);
  }
};
export const fetchSmsCharges = (payload) => {
  try {
    return axios({
      method: "get",
      url: APIUrl.FETCH_SMS_CHARGES + `?property_id=${payload}`,
    });
  } catch (error) {
    throw new Error(error);
  }
};
export const fetchSmsChargesInvoice = (payload) => {
  try {
    return axios({
      method: "get",
      url: APIUrl.FETCH_SMS_CHARGES_INVOICE + `?transfer_id=${payload}`,
      responseType: "blob",
    });
  } catch (error) {
    throw new Error(error);
  }
};

import React from "react";
import { Route, Redirect, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

const PublicRoutes = ({ component: Component, ...rest }) => {
  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
  const location = useLocation();
  const token = localStorage.getItem("access_token");
  // If the user is logged in and has a valid token, redirect to the dashboard
  if (isLoggedIn && token !== null && location.pathname !== "/dashboard") {
    return <Redirect to="/dashboard" />;
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        !isLoggedIn ? <Component {...props} /> : <Redirect to="/dashboard" />
      }
    />
  );
};

export default PublicRoutes;

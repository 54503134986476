import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import menuSlice from "./menu/menuSlice";
import propertySlice from "./property";
import userSlice from "./user/userSlice";
import miscSlice from "./misc/miscSlice";

const appReducers = combineReducers({
  menu: menuSlice,
  user: userSlice,
  property: propertySlice,
  misc: miscSlice,
});

const reducers = (state, action) => {
  if (action.type === "user/logout") {
    state = {};
    storage.removeItem("persist:root");
  }
  return appReducers(state, action);
};

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, reducers);

export default configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
